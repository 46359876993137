import React from 'react'
import SeoPage from '../components/SeoPage'

import Img from '../components/Image'
import { graphql, Link } from 'gatsby'

const ContentPage = ({ data, location, ...props }) => {
    return (
        <SeoPage
            leadImage={data.img0.childImageSharp.fluid.src}
            location={location}
            title="Récidive d'alcool au volant : conséquences et emprisonnement?"
            description="Vous avez de nouveau été arrêté pour conduite avec les facultés affaiblies par l'alcool ? Nous vous expliquons tout ce qu'il faut savoir sur les conséquences d'une récidive !"
            fr={undefined}
            lawType="criminal"
            specialtyText="pour Récidive d’alcool au volant">
            <p>
                Se retrouver à nouveau devant la justice pour conduite avec les
                facultés affaiblies par l’alcool est une situation aux
                conséquences graves.{' '}
            </p>
            <p>
                Après une première condamnation, une récidive entraîne des
                sanctions encore plus sévères, allant d’une révocation prolongée
                du permis de conduire à une peine d’emprisonnement minimale
                obligatoire.
            </p>
            <p>
                <strong>
                    <Img
                        fluid={data.img0.childImageSharp.fluid}
                        title="Conséquences de l’alcool au volant!"
                        alt=""
                    />
                </strong>
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>
                                Dans cet article, nous expliquons en détail les
                                conséquences d'une condamnation pour alcool au
                                volant en cas de récidive!
                            </strong>
                        </p>
                    </td>
                </tr>
            </table>
            <h2>
                Quelles sont les conséquences d’une première condamnation pour
                conduite avec les facultés affaiblies par l’alcool au Québec?
            </h2>
            <p>
                Avant d’aborder les répercussions et les conséquences d’une
                récidive, il est pertinent de comprendre celles d’une première
                infraction liée à l’alcool au volant.
            </p>
            <p>
                <strong>
                    Autrement dit, une personne reconnue coupable en tant que
                    récidiviste a déjà fait face aux sanctions suivantes :
                </strong>
            </p>
            <h4>Suspension immédiate du permis de conduire</h4>
            <p>
                Dès l'arrestation, le permis de conduire est automatiquement
                suspendu par la Société de l'assurance automobile du Québec
                (SAAQ) pour une période de{' '}
                <strong>24 heures ou de 90 jours</strong>.{' '}
            </p>
            <p>
                Cette suspension administrative s'applique avant même que la
                personne ne soit officiellement reconnue coupable. La durée de
                la suspension immédiate dépend des circonstances entourant
                l'arrestation.
            </p>
            <h4>Révocation du permis après condamnation</h4>
            <p>
                Si le tribunal reconnaît la culpabilité du conducteur, celui-ci
                perdra son permis de conduire pour une période minimale d’un an.
                Dans certaines circonstances aggravantes, le juge peut prolonger
                cette révocation jusqu’à trois ans.
            </p>
            <h4>Possibilité de saisie du véhicule</h4>
            <p>
                Dans les cas où le taux d’alcoolémie atteint ou dépasse 160 mg
                par 100 ml de sang, ou si le conducteur refuse de se soumettre
                au test d’alcoolémie, son véhicule peut être saisi.{' '}
            </p>
            <p>
                Cette saisie est de 30 jours et s’accompagne de frais de
                remorquage et d’entreposage que le contrevenant devra payer.
            </p>
            <p>
                Il est à noter que si la personne arrêtée n’est pas le
                propriétaire du véhicule, il est parfois possible de demander
                une <strong>mainlevée de saisie</strong>, ce qui permet de
                récupérer le véhicule avant la fin de la période de saisie, mais
                cette demande doit être validée par un juge ou la SAAQ.
            </p>
            <h4>Peine d’emprisonnement possible</h4>
            <p>
                Bien qu’aucune peine minimale de prison ne soit imposée pour une
                première infraction, le tribunal peut néanmoins ordonner une
                incarcération en fonction de la gravité des circonstances. Dans
                les cas les plus sérieux, la peine peut aller jusqu’à 10 ans de
                prison.
            </p>
            <h4>Installation d’un dispositif antidémarrage</h4>
            <p>
                Selon la décision du juge, la personne reconnue coupable peut
                être obligée d’installer un dispositif antidémarrage sur son
                véhicule. Cet appareil empêche le démarrage du moteur si de
                l’alcool est détecté dans l’haleine du conducteur.
            </p>
            <h4>Participation à des programmes de sensibilisation</h4>
            <p>
                Les personnes reconnues coupables devront suivre des programmes
                tels que le Programme d’évaluation et de réduction du risque de
                conduite avec les facultés affaiblies et Alcofrein.{' '}
            </p>
            <p>
                <Img
                    fluid={data.img1.childImageSharp.fluid}
                    title="Participation obligatoire au programme Alcofrein!"
                    alt=""
                />
            </p>
            <p>
                Ces formations ont pour objectif de sensibiliser les conducteurs
                aux dangers de l’alcool au volant et de leur fournir des outils
                pour éviter de répéter cette erreur.
            </p>
            <h4>Amendes imposées en fonction du taux d’alcool</h4>
            <p>
                L’amende minimale imposée au contrevenant varie selon la
                concentration d’alcool dans le sang au moment de l’arrestation :
            </p>
            <ul>
                <li>
                    <strong>Entre 80 et 119 mg par 100 ml de sang</strong> : 1
                    000 $ d’amende
                </li>
                <li>
                    <strong>Entre 120 et 159 mg par 100 ml de sang</strong> : 1
                    500 $ d’amende
                </li>
                <li>
                    <strong>160 mg et plus, ou refus de l’alcootest</strong> : 2
                    000 $ d’amende
                </li>
            </ul>
            <h4>Inscription au casier judiciaire</h4>
            <p>
                Une condamnation pour conduite avec les facultés affaiblies
                entraîne l’ouverture d’un casier judiciaire.{' '}
            </p>
            <h2>
                <strong>
                    <strong>
                        Quelles sont les conséquences d'une RÉCIDIVE{' '}
                    </strong>
                </strong>
                pour conduite avec les facultés affaiblies par l’alcool au
                Québec
                <strong>
                    <strong>?</strong>
                </strong>
            </h2>
            <p>
                En cas de deuxième infraction pour conduite avec les facultés
                affaiblies par l'alcool dans un délai de 10 ans, les sanctions
                au Québec deviennent nettement plus sévères.{' '}
            </p>
            <p>
                Les lois, tant le <em>Code de la sécurité routière</em> que le{' '}
                <em>Code criminel</em>, prévoient des mesures immédiates dès
                l'arrestation ainsi que des conséquences à long terme plus
                lourdes après une deuxième condamnation, notamment :
            </p>
            <h4>
                Suspension immédiate du permis de conduire et interdiction de
                conduire
            </h4>
            <p>
                Lorsqu'un conducteur est arrêté pour une deuxième infraction
                pour alcool au volant, les mesures appliquées sont strictes dès
                l'arrestation. L’une des premières conséquences est la{' '}
                <strong>
                    suspension immédiate du permis de conduire pour une durée de
                    90 jours
                </strong>
                .{' '}
            </p>
            <p>
                En plus de la suspension immédiate de 90 jours lors de
                l’arrestation, la personne reconnue coupable se verra imposer
                une interdiction de conduire d’une{' '}
                <strong>durée minimale de deux ans</strong>.
            </p>
            <h4>Saisie du véhicule</h4>
            <p>
                En plus de la suspension du permis, le véhicule du contrevenant
                sera{' '}
                <strong>
                    immédiatement saisi pour une période de 90 jours
                </strong>
                .{' '}
            </p>
            <h4>Révocation du permis après condamnation</h4>
            <p>
                Une révocation du permis de conduire est imposée lorsque le
                conducteur est reconnu coupable. Celle-ci peut durer de{' '}
                <strong>trois à cinq ans</strong>, voire plus, selon la gravité
                de la situation et la décision du juge.
            </p>
            <h4>Peine d’emprisonnement obligatoire</h4>
            <p>
                Une autre sanction inévitable en cas de récidive est une peine
                d’emprisonnement. Pour une deuxième infraction, une{' '}
                <strong>peine minimale de 30 jours de prison</strong> est
                imposée.{' '}
            </p>
            <p>
                <Img
                    fluid={data.img2.childImageSharp.fluid}
                    title="Peine d’emprisonnement en cas de récidive pour alcool au volant!"
                    alt=""
                />
            </p>
            <p>
                Toutefois, cette durée peut être prolongée jusqu'à 10 ans, selon
                les circonstances spécifiques de l’infraction et l’appréciation
                du tribunal.
            </p>
            <h4>Restrictions sur les véhicules</h4>
            <p>
                Une deuxième condamnation peut aussi entraîner des restrictions
                concernant l’utilisation des véhicules. Le tribunal peut
                interdire à la personne condamnée{' '}
                <strong>
                    d’immatriculer, d’acquérir, de louer ou de mettre en
                    circulation un véhicule à son nom
                </strong>
                .{' '}
            </p>
            <h4>Dispositif antidémarrage à vie</h4>
            <p>
                Lors d’une récidive, le tribunal ordonne l’installation
                obligatoire d’un dispositif antidémarrage à vie dans le véhicule
                du conducteur. Cependant, après 10 ans, il est possible de
                demander son retrait.
            </p>
            <h4>Participation à des programmes de sensibilisation</h4>
            <p>
                Le conducteur reconnu coupable peut être condamné à suivre le
                Programme d’évaluation et de réduction du risque de conduite
                avec les facultés affaiblies.
            </p>
            <h4>Amendes imposées en fonction du taux d’alcool</h4>
            <p>
                Une amende est imposée au contrevenant, et son montant varie en
                fonction de la concentration d'alcool dans le sang au moment de
                l'arrestation :
            </p>
            <ul>
                <li>
                    <strong>Entre 80 et 119 mg par 100 ml de sang</strong> : 1
                    000 $ d’amende
                </li>
                <li>
                    <strong>Entre 120 et 159 mg par 100 ml de sang</strong> : 1
                    500 $ d’amende
                </li>
                <li>
                    <strong>160 mg et plus, ou refus de l’alcootest</strong> : 2
                    000 $ d’amende
                </li>
            </ul>
            <h4>Inscription au casier judiciaire</h4>
            <p>
                La deuxième condamnation pour conduite avec les facultés
                affaiblies est inscrite au casier judiciaire du contrevenant.
            </p>
            <p>
                Bref, une deuxième condamnation pour conduite avec les facultés
                affaiblies par l’alcool entraîne des sanctions plus sévères que
                celles d'une première infraction, telles qu'une peine minimale
                d'emprisonnement obligatoire, la suspension et la révocation
                prolongées du permis de conduire, l'installation d'un
                antidémarreur à vie, ainsi que des restrictions possibles sur
                l'usage des véhicules.
            </p>
            <h3>
                Comment récupérer son permis de conduire après une récidive
                d’alcool au volant ?
            </h3>
            <p>
                Après une deuxième infraction pour alcool au volant dans un
                délai de moins de 10 ans, la possibilité de récupérer un permis
                de conduire devient plus complexe, même après la période de
                sanctions.{' '}
            </p>
            <p>
                <strong>
                    Il n’est pas possible de récupérer un permis de conduire
                    standard ; il faut obtenir un permis de conduire assorti de
                    la condition X.{' '}
                </strong>
            </p>
            <p>
                <strong>
                    <Img
                        fluid={data.img3.childImageSharp.fluid}
                        title="Récupérer son permis après une récidive d’alcool au volant!"
                        alt=""
                    />
                </strong>
            </p>
            <p>
                <strong>
                    Voici les étapes à suivre pour obtenir ce permis de conduire
                    :
                </strong>
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>Étapes</strong>
                        </p>
                    </td>
                    <td>
                        <p>
                            <strong>Explication</strong>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <ol>
                            <li>Réception de la lettre de la SAAQ</li>
                        </ol>
                    </td>
                    <td>
                        <p>
                            Dès la réception du jugement du tribunal, la SAAQ
                            enverra au contrevenant une lettre confirmant sa
                            culpabilité, la révocation de son permis ainsi que
                            la durée de la sanction.{' '}
                        </p>
                        <p>
                            Cette lettre précisera également la possibilité
                            d’obtenir un permis restreint, si applicable, et
                            mentionnera l’obligation de conduire un véhicule
                            équipé d’un antidémarreur éthylométrique à vie.
                        </p>
                        <p>
                            De plus, le cas échéant, elle indiquera
                            l’interdiction d’acquérir, de louer ou
                            d’immatriculer un véhicule à son nom pendant la
                            période de sanction.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>1. Admissibilité à la condition</td>
                    <td>
                        <p>
                            Un mois avant la fin de la sanction, la SAAQ enverra
                            au contrevenant une nouvelle lettre pour l'informer
                            qu'il est désormais admissible à un permis assorti
                            de la condition X.{' '}
                        </p>
                        <p>
                            Cela signifie que, pour pouvoir conduire, il devra
                            obligatoirement faire installer un antidémarreur
                            éthylométrique dans son véhicule.{' '}
                        </p>
                        <p>
                            Cette condition restera en place pendant une période
                            de 10 ans, après quoi le contrevenant pourra faire
                            une demande pour en obtenir la levée, mais cette
                            démarche devra être entreprise par lui-même devant
                            la Cour du Québec.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        2. Location et installation de l'antidémarreur
                        éthylométrique
                    </td>
                    <td>
                        <p>
                            Une fois la condition X applicable, le contrevenant
                            devra louer et installer à ses frais un
                            antidémarreur éthylométrique, conformément aux
                            exigences de la SAAQ.{' '}
                        </p>
                        <p>
                            En cas de non-respect de cette obligation, des
                            sanctions sévères pourront être imposées, telles que
                            la saisie immédiate du véhicule pour une période de
                            30 à 90 jours, des amendes allant de 1 500 $ à 3 000
                            $, ou une suspension prolongée du permis.
                        </p>
                        <p>
                            L'installation d'un antidémarreur éthylométrique
                            comporte des frais d'installation de 50 $, ainsi que
                            des frais mensuels de location d'environ 61 $. À la
                            fin de la période de sanction, le retrait de
                            l'appareil et la fermeture du dossier ne génèrent
                            pas de frais supplémentaires.{' '}
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>3. Payer les frais</td>
                    <td>
                        <p>
                            Une fois toutes les conditions remplies et après la
                            période de sanction, la personne concernée devra
                            s'acquitter des frais d'obtention de son nouveau
                            permis, qui varient entre 420 $ et 560 $.
                        </p>
                    </td>
                </tr>
            </table>
            <h3>
                Comment éviter les conséquences d'une récidive grâce à l'aide
                d'un avocat spécialisé en droit criminel?
            </h3>
            <p>
                Pour éviter les conséquences d'une récidive pour conduite avec
                les facultés affaiblies, il est fortement recommandé de
                consulter un avocat spécialisé en droit criminel. Un avocat
                compétent dans ce domaine dispose de plusieurs stratégies pour
                éviter les conséquences imposées en cas de deuxième condamnation
                en dix ans pour conduite avec les facultés affaiblies par
                l'alcool.
            </p>
            <p>
                <strong>
                    L'une des stratégies les plus efficaces consiste à négocier
                    avec la poursuite afin d'éviter l'enregistrement d'un avis
                    de récidive.
                </strong>
            </p>
            <p>
                Dans certaines situations, l’avocat pourra engager des
                négociations avec le procureur de la couronne pour parvenir à un
                accord favorable. Dans ce cas, le conducteur reconnaît sa
                culpabilité, mais l’avis de récidive, qui entraîne
                automatiquement des sanctions sévères telles qu’une peine
                d’emprisonnement obligatoire et d’autres restrictions, ne sera
                pas déposé.{' '}
            </p>
            <p>
                Cette approche permet de réduire les conséquences graves d’une
                deuxième infraction en évitant les peines minimales imposées par
                le <em>Code criminel</em>.
            </p>
            <h2>
                Vous êtes accusé de conduite avec les facultés affaiblies par
                l’alcool pour une deuxième fois? Consultez un avocat spécialisé!
            </h2>
            <p>
                Si vous avez été à nouveau arrêté pour conduite avec les
                facultés affaiblies par l'alcool et que des accusations
                criminelles ont été portées contre vous, il est important de
                consulter un avocat spécialisé en droit criminel. Un avocat
                expérimenté saura vous écouter, comprendre votre situation et
                vous fournir l’assistance nécessaire pour vous défendre.
            </p>
            <p>
                Avoir un avocat à vos côtés vous offre une réelle opportunité de
                réduire les conséquences graves d'une récidive, comme la peine
                d’emprisonnement obligatoire. Il saura analyser votre dossier en
                détail et mettre à profit son expertise pour négocier avec la
                poursuite.
            </p>
            <p>
                <Img
                    fluid={data.img4.childImageSharp.fluid}
                    title="Avocat pour récidive d'alcool au volant!"
                    alt=""
                />
            </p>
            <p>
                N'attendez pas pour contacter un avocat spécialisé en droit
                criminel, car ses services peuvent véritablement influencer
                l’issue de votre dossier. Plus tôt vous agirez, plus vous
                augmenterez vos chances d’obtenir un résultat favorable et de
                réduire les conséquences d’une deuxième infraction.
            </p>
            <p>
                <strong>
                    Grâce à notre formulaire rapide et gratuit, vous pourrez
                    facilement trouver un avocat spécialisé en droit criminel !
                </strong>
            </p>
            <p>
                <strong>
                    Après avoir soumis votre demande, nous vous mettrons en
                    relation rapidement avec un avocat criminaliste près de chez
                    vous, sans aucun engagement de votre part.
                </strong>
            </p>
        </SeoPage>
    )
}

export default ContentPage

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        img0: file(
            relativePath: {
                eq: "recidive-alcool-volant/consequence-alcool-volant.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img1: file(
            relativePath: {
                eq: "recidive-alcool-volant/participation-obligatoire-programme-alcofrein.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img2: file(
            relativePath: {
                eq: "recidive-alcool-volant/prison-obligatoire-recidive-alcool-volant.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img3: file(
            relativePath: {
                eq: "recidive-alcool-volant/recuperer-permis-recidive-alcool-volant.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img4: file(
            relativePath: {
                eq: "recidive-alcool-volant/avocat-recidive-alcool-volant.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
